import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { Accordion, CallToAction, ProductPricing, CustomerQuote } from "../components/site";
import { Container, Wrapper, Row, Box, Button } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

const FreePlanContainer = styled(Box)`
  padding: 40px;
  background-color: ${(props) => props.theme.colours.mint};
  color: ${(props) => props.theme.colours.black};

  h4 {
    font-weight: 500;
  }

  .cost {
    font-size: 5.2rem;
    font-weight: 500;
  }

  .cost_detail {
    line-height: 1.6;
  }

  a {
    color: ${(props) => props.theme.colours.black};

    &.-md {
      min-width: 150px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 20px;

    a.-md {
      width: 100%;
    }
  }
`;

const Pricing = (props) => {
  const FAQs = [
    {
      q: "What types of payment do you accept?",
      a: (
        <p>
          We accept Visa & MasterCard payments. Direct debit payments are
          available to customers in New Zealand.
        </p>
      ),
    },
    {
      q: "When will I be billed?",
      a: (
        <p>
          We bill at the start of each month for usage in the previous month.
        </p>
      ),
    },
    {
      q: "How does the pricing work?",
      a: (
        <>
          <p>
            Droppah is completely free for companies with up to 10 employees.
            You can access all features and there are no restrictions on your
            usage.
          </p>
          <p>
            For companies with 11+ employees the monthly billing is based on
            your usage. That way you’re not paying for inactive employees, such
            as seasonal employees during the off season.
          </p>
          <p>
            For the Time Clock Plan your monthly charge is based on the number
            of unique employees with a timesheet entry in the month. If you have
            an employee who isn’t included on a timesheet during the calendar
            month, you won’t be charged for them.
          </p>
          <p></p>
          <p>
            For The Works Plan your monthly charge is based on the number of
            unique employees in a roster or with a timesheet entry in the month.
            If you have an employee who isn’t included on a roster or timesheet
            during the calendar month, you won’t be charged for them.
          </p>
        </>
      ),
    },
    // {
    //   q: "Is there a discount for using multiple FlexiTime products?",
    //   a: (
    //     <>
    //       <p>
    //         If you use both{" "}
    //         <a href="https://www.payhero.co.nz" target="_blank">
    //           PayHero
    //         </a>{" "}
    //         and Droppah you’ll pay just $3 per employee per month for Droppah
    //         with no monthly base fee.
    //       </p>
    //       <p>
    //         Contact <a href="mailto:support@droppah.com">support@droppah.com</a>{" "}
    //         after subscribing to both products to have your discounted pricing
    //         applied.
    //       </p>
    //     </>
    //   ),
    // },
    {
      q: "Am I locked in?",
      a: (
        <p>
          No. Our standard subscriptions are pay-as-you-go and you can cancel
          your account at any time from your account settings.
        </p>
      ),
    },
    {
      q: "What happens if I cancel my account?",
      a: (
        <p>
          You can cancel at any time and we’ll send you a final invoice for your
          usage in the current billing period.
        </p>
      ),
    },
    {
      q: "Can I try before I buy?",
      a: (
        <>
          <p>
            You sure can! The Droppah Free plan gives you access to all the
            features for up to 10 employees. That means you can try Droppah out
            and do your testing before deciding to upgrade to a paid plan.
          </p>
        </>
      ),
    },
    // {
    //   q: "How do I pay a gig worker?",
    //   a: (
    //     <p>
    //       To accept an offer you will be prompted to pay for the shift with a credit 
    //       card payment. These payments are not included in your Droppah account bill. 
    //       There will be a 10% charge incurred on top of the hourly rate, excluding GST. {" "}
    //       <a 
    //         href="https://support.droppah.com/hc/en-us/articles/8312427375375-What-is-Hire-" 
    //         target="_blank"
    //         rel="noopener noreferrer"
    //       >
    //         Read more about Hire here.
    //       </a>
    //     </p>
    //   ),
    // },
  ];

  return (
    <Layout>
      <Seo
        title="Pricing | Rosters, Time Tracking & Attendance Software | Droppah"
        description="The complete package for staff scheduling and time & attendance - free for up to 10 employees. Get started today."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper stackGap={80}>
          <div className="-textCenter">
            <h4>Free Rostering & Time Clock for up to 10 Employees</h4>
            <h1 className="-fontBold -fontLarge">Pricing</h1>
          </div>
          <ProductPricing />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper stackGap={60}>
          <CustomerQuote
            quote="Droppah is a modern solution to a persistent problem."
            name="Kieran Wallace"
            company={<Link to="/case-studies/1154-pastaria-and-scopa-pizzeria">1154 Pasteria</Link>}
            pic="1154_Logo_White.png"
            center
            large
          />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper maxWidth={800}>
          <Box stackGap={60}>
            <h2 className="-textCenter">Frequently asked questions</h2>
            <Box stackGap={25}>
              {FAQs.map((item, i) => {
                return (
                  <Accordion key={i} title={item.q} light>
                    {item.a}
                  </Accordion>
                );
              })}
            </Box>
            <h4 className="-textCenter">
              More questions? <Link to="/contact">Talk to Sales</Link>.
            </h4>
          </Box>
        </Wrapper>
      </Container>
      <CallToAction />
    </Layout>
  );
};

export default Pricing;
